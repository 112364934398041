import WPSeo from 'gatsby-plugin-wpgraphql-seo';
import React from 'react';
import { Helmet } from 'react-helmet';

interface SeoProps {
  title?: String;
  meta?: [];
  post?: any;
  postSchema?: IPageSchema;
}

interface IPageSchema {
  '@context': string;
  '@graph': IPageSchemaItems[];
}
interface IPageSchemaItems {
  '@type': any;
  '@id': string;
  url: string;
  name: any;
  isPartOf: {
    '@id': string;
  };
  description: any;
  inLanguage: String;
  potentialAction: {
    '@type': string;
    target: string[];
  }[];
  datePublished?: string;
  dateModified?: string;
}

const Seo: React.FC<SeoProps> = (props) => {
  const meta = [
    {
      property: 'og:image',
      content:
        props.post?.seo?.opengraphImage?.sourceUrl || '/images/og-image.jpg',
    },
  ];

  if (props.post?.seo?.twitterImage?.sourceUrl) {
    meta.push({
      property: 'twitter:image',
      content: props.post?.seo?.twitterImage?.sourceUrl,
    });
  }

  return (
    <>
      <WPSeo {...props} />
      <Helmet meta={meta} />
    </>
  );
};

export default Seo;
